@tailwind base;
@tailwind components;
@tailwind utilities;

.front-intro{
    @apply text-5xl;
}
.home-banner-text{
  @apply ml-28 mt-7;
}
* {
    font-family: "Albert Sans", sans-serif;
    font-optical-sizing: auto;
}
.btn-icon{
  width: 1.3em;
  height: 1.3em;
  fill: white;
  @apply my-auto ml-3;
}
.heading-icon{
  @apply fill-white h-8;
}
.main-menu{
 @apply w-full bg-gray-50 p-1 flex fixed top-0 shadow-md;
}
.dropdown{
  position: relative;
}
.dropdown-menu{
  @apply hidden absolute z-10 bg-gray-50 p-2 rounded-lg w-44 shadow-md;
}
.dropdown-menu a {
  @apply mb-3 hover:text-purple-700 hover:cursor-pointer;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.main-logo{
  @apply  ml-12 w-40 items-center;
}
.menu-items{
  @apply flex h-full w-full my-auto ml-72 gap-14
}
.menu-item{
  @apply text-base;
}
.menu-bars{
  @apply hidden;
}
.mobile-menu-items{
  @apply hidden;
}
.home-banner{
  @apply flex pt-36 pb-12 gap-32 border-b-2 bg-cover bg-no-repeat border-purple-200;
  background-image: url('../media/home-banner-bg.jpg')
}
.main-body{
  @apply bg-gray-50 w-full flex flex-col min-h-screen pb-24;
}
.title-area{
  @apply w-full bg-gray-100;
}
.page-title{
  @apply w-fit bg-slate-600 pt-20 shadow-lg pb-2 pl-40 pr-5 text-3xl text-white;
}
.front-image{
  width: 25%;
  margin-left: 5%;
  -webkit-user-drag: none;
  user-drag: none;
  user-select: none;        /* Prevent text selection */
  -webkit-user-select: none;/* Prevent text selection (for Safari) */
  -moz-user-select: none;   /* Prevent text selection (for Firefox) */
  -ms-user-select: none;    /* Prevent text selection (for Internet Explorer/Edge) */
  pointer-events: none;     /* Prevent any mouse interactions */
  }
  .feature{
    @apply border-2 border-gray-300 px-6 py-8 rounded-2xl shadow-lg bg-white;
  }
  .features-section{
    @apply flex px-40 py-20 gap-10 justify-center text-center w-full;
  }
  .feature img {
    user-select: none;
    pointer-events: none
  }
  .white-body{
    @apply bg-white rounded-lg mx-12 my-4 p-12;
  }
  .about{
    @apply flex flex-wrap gap-12;
  }
  .about-pic{
    @apply basis-1/6 w-1/6 rounded-lg;
  }
  .about-body{
    @apply basis-3/5
  }
  .input-field{
    @apply border p-2 w-2/3 border-gray-300 rounded-lg; 
  }
  .pack-icon{
    @apply mx-auto w-20;
  }
  .icons-area{
    @apply grid grid-cols-5 gap-4 justify-center mx-48 my-10 gap-y-10;
  }
  .icon{
    @apply bg-white border-2 w-3/4 p-4 rounded-xl hover:cursor-pointer hover:shadow-md shadow-sm;
    background-image: url("../media//watermark.svg");
    background-size: contain
  }
  .modal-backdrop{
    @apply z-20 bg-gray-900/50 w-screen h-screen fixed top-0;
  }
  .icon-modal{
    @apply z-30 bg-white border-2 border-gray-200 fixed top-16 left-1/4 rounded-xl w-2/4;
  }
  .icon-modal-inner{
    @apply px-12 py-2;
  }
  .icon-page-area{
    @apply bg-white py-8 px-2 w-3/5 ml-auto mr-auto h-full;
  }
  .icons-page-area{
    @apply grid grid-cols-3 my-6 gap-4 w-2/6 py-3 px-6 bg-gray-50 border rounded-lg;
    background: rgb(253, 253, 253); 
  }
  .icons-page-area .icon{
    width: 70%;
    height: fit-content;
    padding-bottom: 6px;
  }
  .icon-modal label{
    @apply ml-1 text-lg;
  }
  .icon-page-area label{
    @apply ml-1 text-lg;
  }
  .icon-details-page-div{
    @apply bg-white h-full;
  }
  .icon-buttons{
    @apply flex gap-2;
  }
  .icon-page-preview{
    @apply p-4 w-full mx-auto;
    user-select: none;
    pointer-events: none;
  }
.preview-inner{
  @apply flex;
}
  .icon-details-1{
    @apply basis-3/6;
    background-image: url("../media//watermark.svg");
    background-size: contain;
  }
  .icon-details-2{
    @apply basis-4/5 my-auto;
  }
  .industry-pack{
    @apply px-3 py-8  w-1/5 rounded-2xl shadow-lg;
  }
  .show{
    display: block;
    transition: 2s;
  }
  .hide{
    display: none;
  }
  svg{
    vertical-align: top;
  }
  .premium-tag{
    @apply bg-purple-200 mx-auto py-1 px-2 rounded-lg text-center w-fit;
    font-size: 12px;
  }
  .radio{
    @apply mr-3;
  }
  .search-bar{
   @apply w-2/4 py-3 px-10 text-xl text-black rounded-full
  }
  .icon-set{
    user-select: none;
    pointer-events: none;
    @apply m-auto;
  }
  .steps-section{
    @apply bg-yellow-600 text-center text-white  w-5/6 shadow-lg rounded-xl mx-auto py-6 px-6;
  }
  .steps-inner{
    @apply flex gap-8;
  }
  .step{
    @apply basis-3/6 text-center flex flex-col bg-yellow-700 justify-center p-2 rounded-2xl m-2;
  }
  .footer{
    @apply static bottom-0 pt-8 pb-12 px-10 w-full bg-gray-200 flex;
  }
  .docs{
    @apply w-full ml-14 mt-5;
  }
  .docs-inner{
    @apply w-full flex gap-6;
  }
  .docs-1{
    @apply basis-1/5 bg-white border-2 border-gray-300 rounded-xl p-8 h-fit;
  }
  .docs-2{
    @apply bg-white basis-4/6 border-2 border-gray-300 rounded-xl p-8 h-fit;
  }
  .docs h4{
    @apply text-2xl mb-2 font-bold text-purple-800;
  }
  .docs h5{
    @apply text-lg mb-2 font-bold;
  }
  .docs p{
    @apply mb-2;
  }
  .docs li{
    @apply text-lg mb-2
  }
  .docs li li {
    @apply text-base mb-2 ml-4
  }
  .plans-area{
    @apply flex gap-10 justify-center;
  }
  .plan{
    @apply w-1/4 bg-white p-10 rounded-lg text-center border shadow-sm border-gray-400;
  }
  .plan-button{
    @apply py-3 px-5 w-3/5 border-2 border-gray-400 rounded-lg text-lg; 
  }
  .login-box{
    @apply mx-auto my-auto px-20 py-10 bg-white w-2/6 rounded-lg shadow-xl;
  }
  .input{
    @apply w-full border-2 px-5 py-1 rounded-lg;
  }
  .login-btn{
    @apply w-full p-2  rounded-lg text-white bg-purple-600;
  }
  .footer-legal{
    @apply bg-gray-900 py-3 px-12 text-white;
  }
  .ft-2{
    @apply flex gap-40;
  }
  .payment-area{
    @apply flex p-10 bg-white mx-10 rounded-2xl;
  }
  .pa-1{
    @apply border-r border-gray-300
  }
  .payment-form{
    @apply bg-gray-50 m-5 p-10 rounded-3xl;
  }
  .terms-body{
    @apply px-12 py-4 bg-white m-5 rounded-xl leading-7;
  }
  .button-flex{
    @apply flex gap-3;
  }
  .account-box{
    @apply bg-white w-4/5  mx-auto p-10 rounded-lg shadow-lg my-auto;
  }
  .posts-body{
    @apply py-3 px-12;
  }
  .post{
    @apply mx-auto shadow-md bg-white p-4 border-2 border-gray-300 my-8 w-5/6 rounded-xl;
  }
  .post-content-area{
    @apply flex flex-wrap;
  }
  .post-excerpt{
    @apply basis-4/6;
  }
  .post-image{
    @apply basis-2/6 rounded-lg;
  }
  .post-area{
    @apply pt-24;
  }
  .post-read{
    @apply w-4/6 p-8 rounded-3xl mx-auto shadow-md bg-white
  }
  .article-ad{
    @apply text-white mx-auto;
    width: 35%;
  }
  .post-read-content {
    h4{
    @apply text-2xl;
    }
    h5{
    @apply text-xl;
    }
    h3{
      @apply text-3xl;
    }
    h1,h2,h3,h4,h5{
      @apply py-2
    }
  }

  .post-read{
    @apply leading-8;
  }

  .loader{
    position: absolute;
    top: 30%;
    left: 40%;
    width: fit-content;
    z-index: 10;
    background: rgb(255, 255, 255, 0.8);
    @apply p-2 shadow-xl rounded-2xl;
  }
  .loader img{
    width: 200px;
    margin-bottom: 5px;
  }
  .edit-body{
    @apply flex flex-wrap justify-center gap-5 m-3;
  }
  .ed-1{
    @apply w-3/6
  }
  .ed-2{
    @apply w-2/6 bg-white p-5 rounded-xl shadow-lg;
  }

  @media (max-width: 767px) {
    .main-menu{
      @apply m-auto pl-4 pr-5;
    }
    .menu-bars{
      @apply block text-right justify-self-end w-5 h-4 my-auto ml-auto;
  
    }
    .main-logo{
      @apply w-2/4 ml-1;
    }
    .home-banner-text{
      @apply ml-7;
    }
    .mobile-menu-items{
      @apply z-40 w-screen border border-gray-200 h-fit bg-gray-50 fixed shadow-lg mt-10 p-8 text-3xl;
    }
    .front-intro{
      font-size: 36px;
    }
    .menu-items{
      @apply hidden;
    }
    .home-banner{
      @apply w-full gap-24 pt-28 flex-wrap bg-cover;
      background-image: url('../media/mobile-home-banner-bg.jpg')
    }
    .front-image{
      @apply w-4/5 mx-auto;
    }
    .features-section{
      @apply flex-wrap flex-col px-0 justify-center;
    }
    .feature{
      @apply w-3/4 gap-5 mx-auto text-center;
    }
    .steps-section{
      @apply w-5/6 mx-auto;
    }
    .steps-inner{
      @apply flex-wrap gap-10 flex-col;
    }
    .step{
      @apply w-full m-0 min-h-72;
    }
    .footer{
      @apply w-full flex-wrap px-4 flex-col;
    }
    .ft-1{
      @apply w-full mb-8;
    }
    .ft-2{
      @apply gap-8  ml-4
    }
    .icons-area{
      @apply grid-cols-2 gap-4 mx-4 ml-8;
    }
    .icon{
      @apply w-full
    }
    .search-bar{
      @apply w-5/6
    }
    .icon-modal{
      @apply left-5;
      width: 90%;
      overflow-y: auto;
      overflow-x: hidden
    }
    .icon-modal-inner{
      @apply flex-wrap justify-center;
      height: 400px;
    }
    .icon-modal-inner svg{
      @apply mb-10
    }
    .icon-details-page-div{
      @apply block w-full justify-center;
    }
    .icon-page-area{
      @apply w-full px-8;
    }
    .icons-page-area{
      @apply w-full;
    }
    .icons-page-area .icon{
      @apply w-full;
    }
    .icon-buttons{
      @apply flex-col w-fit;
    }
    .icon-details-1{
      @apply w-full;
    }
    .docs{
      @apply m-0 p-4;
    }
    .docs-inner{
      @apply flex-wrap flex-col px-0 m-0;
    }
    .docs-1, .docs-2{
      @apply  w-full mx-0;
    }
    .title-area{
      @apply w-full;
    }
    .page-title{
      @apply text-2xl pl-20 w-fit;
    }
    .preview-inner{
      @apply block;
    }
    .plans-area{
      @apply flex-wrap flex-col;
    }
    .pricing-intro{
      @apply px-3;
    }
    .plan{
      @apply mx-auto;
      width: 90%;
    }
    .payment-area{
      @apply flex-wrap flex-col mx-auto p-2;
      width: 90%;
    }
    .pa-1{
      @apply mx-auto border-r-0 p-2 w-5/6;
    }
    .pa-2{
      width: 100%;
      @apply p-2;
    }
    .menu-item{
      @apply text-lg
    }
    .login-box{
      @apply w-5/6 mx-auto p-7;
    }
    .button-flex{
      @apply flex-col;
    }
    .post-read{
      width: 90%;
    }
    .post{
      width: 90%;
    }
    .posts-body{
      @apply px-1;
    }
    .post-content-area{
      @apply block;
    }
    .post-image{
      @apply pt-2 pb-4 rounded-xl;
    }
    .article-ad{
      @apply w-full;
    }
    .loader{
      left: 20%;
    }
    .industry-pack{
      @apply w-5/6;
    }
    .about-pic{
      @apply basis-full;
    }
    .about-body{
      @apply basis-full;
    }
    .white-body{
      @apply m-4;
    }
  }